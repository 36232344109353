import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import { IconMarker } from 'ToolboxComponents/commons/map-gpx/icons/marker';
import { MapGpxWrapper } from 'ToolboxComponents/commons/map-gpx/map-gpx.styles';
import { ThemeContext, WhiteLabelContext } from 'ToolboxUtils/web/context/context';
import { User } from 'ToolboxComponents/commons/map-gpx/icons/user';
import bbox from '@turf/bbox';
import PropTypes from 'prop-types';
import tj from '@mapbox/togeojson';
import WebMercatorViewport from '@math.gl/web-mercator';
import 'leaflet/dist/leaflet.css';

const apiServerCors = process.env.CONFIG.API_CORS;

const MapGPX = ({ clickLocation, gpx, height, itemLocations, selectedSlide, userLocation, width }) => {
  const { color } = useContext(ThemeContext).state;

  const [mapGPX, setMapGPX] = useState(React.createRef());
  const [geometry, setGeometry] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selected, setSelected] = useState(selectedSlide);
  const [viewport, setViewport] = useState({
    bearing: 60,
    height: '100%',
    latitude: itemLocations[0].latitude,
    longitude: itemLocations[0].longitude,
    pitch: 60,
    width: '100%',
    zoom: 10,
  });

  useEffect(() => {
    if (isLoaded) {
      setSelected(selectedSlide || 0);
      if (itemLocations[selectedSlide]) {
        flyToMarker({ longitude: itemLocations[selectedSlide].longitude, latitude: itemLocations[selectedSlide].latitude });
      }
    }
  }, [selectedSlide, itemLocations, isLoaded]);

  useEffect(() => {
    async function fetchGPX() {
      const response = await fetch(`${apiServerCors}/?url=${gpx}`);
      const textString = await response.text();
      const data = await new DOMParser().parseFromString(
        textString,
        'text/xml',
      );
      setGeometry(tj.gpx(data).features[0].geometry);
    }
    fetchGPX();
  }, []);

  const fill = color.primaryColor;
  const fillOff = color.secondaryColor;

  const traceTrail = map => {
    map.addLayer({
      "id": "route",
      "type": "line",
      "source": {
        "type": "geojson",
        "data": {
          "type": "Feature",
          "properties": {},
          "geometry": geometry,
        }
      },
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-color": "#f00",
        // "line-color": color.quaternaryColor,
        "line-width": 8
      }
    });
  };

  const updateSelected = id => {
    setSelected(id);
    clickLocation(id);
  };

  const switchIcon = id => {
    return id === selected
        ? <div onClick={() => updateSelected(id)} style={{zIndex: 10}}><IconMarker fill={fill} width={30} height={30} /></div>
        : <div onClick={() => updateSelected(id)}><IconMarker fill={fillOff} /></div>
  };

  const displayMarkers = () => {
    return (
      itemLocations.map((item, index) => (
        <Marker
          key={index}
          id={index}
          latitude={item.latitude}
          longitude={item.longitude}
          className={index === selected ? 'selected' : '1'}
          >
            {switchIcon(index)}
          </Marker>
        ))
    );
  };

  const displayUserLocationMarker = () => {
    return (
      userLocation && userLocation.latitude !== null &&
        <Marker
          latitude={userLocation.latitude}
          longitude={userLocation.longitude}
          offsetLeft={-20}
          offsetTop={-20}
          zIndexOffset={10000}
        >
          {<User fill={color.primaryColor} />}
        </Marker>
    )
  };

  const flyToMarker = ({ longitude, latitude }) => {
    if (mapGPX.current.getMap()) {
      mapGPX.current.getMap().flyTo({
        center: [longitude,latitude],
        zoom: 14
      });
      setViewport({ ...viewport, longitude, latitude, zoom: 14, transitionDuration: 500 });
    }
  }

  const loadMap = () => {
    const map = mapGPX.current.getMap();
    traceTrail(map);
    const bounds = bbox(geometry);

    const updatedViewport = {
      ...viewport,
      longitude: bounds[0] + ((bounds[2] - bounds[0]) / 2),
      latitude:  bounds[1] + ((bounds[3] - bounds[1]) / 2),
    };

    const newViewport = new WebMercatorViewport(updatedViewport);
    const { longitude, latitude, zoom } = newViewport.fitBounds(
      [[bounds[2], bounds[3]], [bounds[0], bounds[1]]],
      {padding: 30}
    );
    setViewport({
      ...newViewport,
      longitude,
      latitude,
      zoom,
      transitionDuration: 500
    });
    setIsLoaded(true);
  };
  return (
    <MapGpxWrapper>
      <ReactMapGL
        {...viewport}
        ref={mapGPX}
        doubleClickZoom={false}
        mapboxApiAccessToken={process.env.CONFIG.MAPBOX_TOKEN}
        mapStyle={'mapbox://styles/agitateurs/cjoipyxim40in2so94v0sdspr'}
        touchRotate
        onViewportChange={(newViewport) => {
          setViewport({ ...viewport, ...newViewport });
        }}
        onResize={() => {
          const map = mapGPX.current !== null && mapGPX.current.getMap();
          if (map) map.resize();
        }}
        onLoad={() => loadMap()}
      >
        {displayMarkers()}
        {displayUserLocationMarker()}
      </ReactMapGL>
    </MapGpxWrapper>
  );
};

MapGPX.propTypes = {
  clickLocation: PropTypes.func.isRequired,
  gpx: PropTypes.string.isRequired,
  itemLocations: PropTypes.array.isRequired,
  selectedSlide: PropTypes.number.isRequired,
  userLocation: PropTypes.object,
};

export default MapGPX;
